function removeLocalePrefix(pathname) {
    return pathname.replace(/^\/(ms|zh)(\/|$)/, '/');
}

const metaDataTemplates = {
    '/': (intl) => ({
        title: intl.formatMessage({ id: 'indexTitle' }),
        description: intl.formatMessage({ id: 'indexDesc' }),
        canonicalUrl: `${window.location.origin}${intl.locale === 'en' ? '' :  '/' + intl.locale}`
    }),
    '/about-us': (intl) => ({
        title: intl.formatMessage({ id: 'aboutTitle' }),
        description: intl.formatMessage({ id: 'aboutDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}about-us`
    }),
    '/promotion': (intl) => ({
        title: intl.formatMessage({ id: 'promotionTitle' }),
        description: intl.formatMessage({ id: 'promotionDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}promotion`
    }),
    '/vip': (intl) => ({
        title: intl.formatMessage({ id: 'vipTitle' }),
        description: intl.formatMessage({ id: 'vipDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}vip`
    }),
    '/sportsbook': (intl) => ({
        title: intl.formatMessage({ id: 'sportTitle' }),
        description: intl.formatMessage({ id: 'sportDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}sportsbook`
    }),
    '/esport': (intl) => ({
        title: intl.formatMessage({ id: 'esportTitle' }),
        description: intl.formatMessage({ id: 'esportDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}esport`
    }),
    '/casino': (intl) => ({
        title: intl.formatMessage({ id: 'casinoTitle' }),
        description: intl.formatMessage({ id: 'casinoDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}casino`
    }),
    '/slot': (intl) => ({
        title: intl.formatMessage({ id: 'slotTitle' }),
        description: intl.formatMessage({ id: 'slotDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}slot`
    }),
    '/fishing': (intl) => ({
        title: intl.formatMessage({ id: 'fishingTitle' }),
        description: intl.formatMessage({ id: 'fishingDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}fishing`
    }),
    '/lottery': (intl) => ({
        title: intl.formatMessage({ id: 'lotteryTitle' }),
        description: intl.formatMessage({ id: 'lotteryDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}lottery`
    }),
    '/tnc': (intl) => ({
        title: intl.formatMessage({ id: 'tncTitle' }),
        description: intl.formatMessage({ id: 'tncDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}tnc`
    }),
    '/responsible-gaming': (intl) => ({
        title: intl.formatMessage({ id: 'responsibleTitle' }),
        description: intl.formatMessage({ id: 'responsibleDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}responsible-gaming`
    }),
    '/register': (intl) => ({
        title: intl.formatMessage({ id: 'registerTitle' }),
        description: intl.formatMessage({ id: 'registerDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}register`
    }),
    '/download': (intl) => ({
        title: intl.formatMessage({ id: 'downloadTitle' }),
        description: intl.formatMessage({ id: 'downloadDesc' }),
        canonicalUrl: `${window.location.origin}/${intl.locale === 'en' ? '' : intl.locale + '/'}download`
    }),
    '/trusted-online-casino-malaysia': (intl) => ({
        title: intl.formatMessage({ id: 'SeoTrustTitle' }),
        description: intl.formatMessage({ id: 'SeoTrustDesc' }),
        canonicalUrl: `${window.location.origin}/trusted-online-casino-malaysia`
    }),
    '/maxbet': (intl) => ({
        title: intl.formatMessage({ id: 'MaxbetTitle' }),
        description: intl.formatMessage({ id: 'MaxbetDesc' }),
        canonicalUrl: `${window.location.origin}/maxbet`
    }),
    '/live-casino-malaysia': (intl) => ({
        title: intl.formatMessage({ id: 'LiveTitle' }),
        description: intl.formatMessage({ id: 'LiveDesc' }),
        canonicalUrl: `${window.location.origin}/live-casino-malaysia`
    }),
    '/slot-game-malaysia': (intl) => ({
        title: intl.formatMessage({ id: 'SlotTitle' }),
        description: intl.formatMessage({ id: 'SlotDesc' }),
        canonicalUrl: `${window.location.origin}/slot-game-malaysia`
    }),
    '/mega888': (intl) => ({
        title: intl.formatMessage({ id: 'Mega888Title' }),
        description: intl.formatMessage({ id: 'Mega888Desc' }),
        canonicalUrl: `${window.location.origin}/mega888`
    }),
    '/918kiss': (intl) => ({
        title: intl.formatMessage({ id: 'Kiss918Title' }),
        description: intl.formatMessage({ id: 'Kiss918Desc' }),
        canonicalUrl: `${window.location.origin}/918kiss`
    })
};

export default function createMetaData(intl, location) {
    const pathname = removeLocalePrefix(location.pathname);
    const metaDataTemplate = metaDataTemplates[pathname];

    if (metaDataTemplate) {
        return metaDataTemplate(intl);
    } else {
        // 返回默认的元数据
        return {
            title: intl.formatMessage({ id: 'indexTitle' }),
            description: intl.formatMessage({ id: 'indexDesc' }),
            canonicalUrl: `${window.location.origin}${intl.locale === 'en' ? '' : intl.locale + '/'}`
        };
    }
}