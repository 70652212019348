import { UPDATE_LOCALE }from './actions';

const initialState = {
    locale: localStorage.getItem('userLanguage') || 'en',
};
  
export const localeReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LOCALE:
            return {
                ...state,
                locale: action.payload,
            };
        default:
            return state;
    }
};