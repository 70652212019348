import React from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Helmet } from 'react-helmet';

const structuredData = {
  "@context" : "http://schema.org",
  "@type" : "Casino",
  "name": "Maxbook55",
  "alternateName": "Maxbook55 | Online Casino Malaysia | Trusted Online Casino In Malaysia",
  "@id": "https://maxbook5.net/",
  "logo": "https://maxbook5.net/public/images/maxbook55_logo.png",
  "image": "https://maxbook5.net/public/images/maxbook55_logo.png",
  "description": "Online Casino Malaysia - Maxbook55. The Largest &amp; Most Trusted Betting Site! We offer Live Casino, Slots, Sportsbook and more! Join us now to claim the great deals.",
  "url": "https://maxbook5.net/",
  "address" : {
    "@type": "PostalAddress",
    "addressLocality": "Kuala Lumpur",
    "addressRegion": "Kuala Lumpur",
    "postalCode": "47410",
    "streetAddress": "Kuala Lumpur, Malaysia" 
  },
  "telephone":"601139079514",
  "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "aggregateRating":{
      "@type":"AggregateRating",
      "ratingValue":"5",
      "reviewCount":"36888"
    },
    "priceRange":"5",
    "sameAs": [
      "https://www.instagram.com/maxbo_ok55/",
      "https://www.linkedin.com/in/jenny-lee-49b934205/",
      "https://www.pinterest.com/maxbook55betting/",
      "https://soundcloud.com/maxbook55",
      "https://www.youtube.com/@onlinecasinogamesmalaysiam8000",
      "https://twitter.com/maxbook55"
    ]
};

const faqStructuredData = {
  "@context":"https://schema.org",
  "@type":"FAQPage",
  "mainEntity":[
    {
      "@type":"Question",
      "name":"Which online casino is the best in Malaysia?",
      "acceptedAnswer":{
        "@type":"Answer",
        "text":"If you are looking for the best online casino Malaysia, you are looking at the right spot. One of the best, if not, the biggest and most trusted casino online Malaysia is MAXBOOK55. We offer a huge variety of casino games and each of them is uniquely amazing.\nHuge Variety of Games\nA database of international casino game developers’ games integrated into the online gambling platform such as Microgaming, Playtech, SA Gaming, Pragmatic Play, SpadeGaming, and more. Expect to see online slot games, live dealer casino games, online poker, sports betting, esports betting, fishing games, and more in MAXBOOK55.\n24/7 Customer Support\nWe places our customers at the highest priority. By doing this, we are providing the best customer support team at anytime of the day. If you face any issue or query about your gaming experience, just hit the LiveChat button and our professional team will reply to you within minutes.\nA Plethora of Bonuses and Promotions\nHere at MAXBOOK55, we offer a variety of bonuses and promotions that will keep your head spinning with excitement! Whether you’re looking to play online poker, online slots or live dealer casino games, we have something for every player. MAXBOOK55 offer weekly promotions that will give our players the opportunity to win some of their favourite online casino games as prizes! Join now and get in on all these bonuses and promotions today!"
      }
    },
    {
      "@type":"Question",
      "name":"How to win in Casino Online Malaysia?",
      "acceptedAnswer":{
        "@type":"Answer",
        "text":"Winning in online casinos in Malaysia is possible for everyone. It does not matter if you have never played before or are a veteran, we provides you with an opportunity to win! There are many things that can lead to a successful online gambling experience at MAXBOOK55 and we want our players to explore each one of them until they find what works best for them.\n#1. Pick The Game That Suits You Best\nThere is a wide variety of online casino games at MAXBOOK55, which means that there will be one for you! Try out as many games as you can and pick those that won your feeling and focus on those games.\n#2. Always Check For New Promotions or Bonuses\nAs a player, you would like to know the latest promotions that online casino provides. We provides lots of promotions such as Welcome Bonus, Reload Bonus, Cash Rebates, and more to our players. This way, you can take full advantage of it and still be on the winning side.\n#3. Gamble Responsibly\nOnline Gambling addiction has already been recognized as both a mental health problem and a social issue in those who misuse it. The consequences range from financial problems such as bankruptcy and homelessness to legal troubles. If you realise that you may become an addict, please seek professional assistance from the authorities such as https://www.gamcare.org.uk/ and https://www.begambleaware.org/."
      }
    },
    {
      "@type":"Question",
      "name":"Is MAXBOOK55 Legal in Malaysia?",
      "acceptedAnswer":{
        "@type":"Answer",
        "text":"Yes, of course! MAXBOOK55 is a legitimate online casino operating in Malaysia. It is 100% licensed, and registered, secure, and safe. We are the most trusted and reliable online casino in the region and have a customer base of more than a million already. Below are the points where proves MAXBOOK55 is totally legal to play in Malaysia.\n#1. International Licenses\nMAXBOOK55 is a brand operated based in Malta. We have obtained several international gambling licenses such as Curacao eGaming, UK Gambling Commission, and Malta Gaming Authority. The licensing bodies give us their approval to operate legally as an online casino in Malaysia.\n#2. International Audits\nTo ensure the safe and fair operation of our gaming facility, we invite several auditors from internationally recognized audit companies such as TST Global Limited, iTech Labs Limited, and SGS International Services SAU to conduct annual audits on our company structure and internal control system. This protects players from fund mismanagement or theft by a third party.\n#3. Secured by Trusted Technology Systems\nMAXBOOK55 online gambling platform is integrated with a number of high-security systems to ensure the safety of our members. We utilize the latest encryption technology, which is also used by leading financial institutions such as Visa and MasterCard to prevent any unauthorized access. By adding a level of security on top of these two methods, our casino has become one of the most secure online gaming platforms."
      }
    },
    {
      "@type":"Question",
      "name":"What is the best and safest online casino?",
      "acceptedAnswer":{
        "@type":"Answer",
        "text":"MAXBOOK55 is undoubtedly the safest and most trusted online casino in Malaysia. The online casino is very popular and has been around since 2015. It features a wide selection of games that can be accessed both online and on mobile devices, as well as an online sportsbook to bet on various sporting events from all over the world.\n100% Transparency\nThere are no hidden fees or charges when depositing at MAXBOOK55. All deposits are processed instantly and you can withdraw your winnings immediately following the online casino’s receipt of funds.\nGuaranteed Payout Within Minutes\nWith the latest payment technology integrated into our platform, you are able to deposit and withdraw your funds into your wallet within minutes. All processes are done with the highest security to ensure your safety and security.\nInternational Gambling Licenses\nMAXBOOK55 has obtained a number of international gambling authorities licenses such as PACGOR, BMM, iTechLabs, Gambling Laboratories International, and more. This ensures that every player at our platform enjoys the best and safest online casino experience."
      }
    },
    {
      "@type":"Question",
      "name":"Is it safe to play in Malaysia Online Casino?",
      "acceptedAnswer":{
        "@type":"Answer",
        "text":"It is often asked whether mobile online casinos are safe. Take note that mobile and online casinos utilize the same security measures as their desktop counterparts. Hence, they are quite safe. Nonetheless, it is recommended that players should no use public Wi-Fi while playing, as it is more prone to cybercrime."
      }
    },
    {
      "@type":"Question",
      "name":"How do I register an online casino account and play?",
      "acceptedAnswer":{
        "@type":"Answer",
        "text":"Our online casino registration is the same as every other part on our platform. Registration. Turn to the upper right-hand corner of the screen to the menu and choose Join Now. Make sure that you input all the information you requested, including your username and password. Click the confirm that you are 18 years old above, and your account will be made immediately."}},{"@type":"Question","name":"What is a Welcome Bonus and How can I claim it?","acceptedAnswer":{"@type":"Answer","text":"A Welcome Bonus provides exclusive offers from online casinos to get you to play the places that we offer. You will find many games on our platform that have all a deal connected to them. You must open an account to receive it. You will now take advantage of those rewards after registration is completed."}},{"@type":"Question","name":"How can I deposit or withdraw from the MAXBOOK55 Gaming Wallet?","acceptedAnswer":{"@type":"Answer","text":"Follow the following steps to deposit on your gaming wallet: Depositing:\nLog in to your account.\nSelect the banking option you like and click the Deposit tab.\nChoose the payment method you wish to use.\nFollow the prompts. If you choose credit/debit cards, you need to input personal details.\nEnter the money you like to put into your account.\nEnsure all details are correct.\nClick confirm.\nYour funds will immediately appear.\nWithdrawing:\nLog in to your account and select the Banking tab.\nPick the Withdrawal option.\nFollow the prompts and enter the amount of money you want to process.\nEnsure all details are correct, then click confirm.\nRemember that pending times vary on the system you are using. However, rest assured your funds will be accessible within five working days."
      }
    }
  ]
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(faqStructuredData)}
      </script>
    </Helmet>
    <App />
  </Provider>
);