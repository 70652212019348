import { Utils } from './utils';
import { webApiUrl } from '../defaultWebMsg'; 

export const FETCH_INFORMATION_REQUEST = 'FETCH_INFORMATION_REQUEST';
export const FETCH_INFORMATION_SUCCESS = 'FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_FAILURE = 'FETCH_INFORMATION_FAILURE';
export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
export const FETCH_UPDATE_BANK_SUCCESS = 'FETCH_UPDATE_BANK_SUCCESS';
export const FETCH_UPDATE_PASSWORD_SUCCESS = 'FETCH_UPDATE_PASSWORD_SUCCESS';
export const UPDATE_LOCALE = 'UPDATE_LOCALE';
export const FETCH_UPDATEBIRTHDAY_SUCCESS = 'FETCH_UPDATEBIRTHDAY_SUCCESS';

// 使用 fetch 发送 POST 请求
/*async function postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  
    return await response.json();
}*/

// INFORMATION 
export const fetchInformationRequest = () => {
  return {
    type: FETCH_INFORMATION_REQUEST,
  };
};

export const fetchInformationSuccess = (data) => {
  return {
    type: FETCH_INFORMATION_SUCCESS,
    payload: data,
  };
};

export const fetchInformationFailure = (error) => {
  return {
    type: FETCH_INFORMATION_FAILURE,
    payload: error,
  };
};

// LOGIN 
export const fetchLoginRequest = () => {
  return {
    type: FETCH_LOGIN_REQUEST,
  };
};

export const fetchLoginSuccess = (loginState, profile) => {
  localStorage.setItem('login', loginState);
  localStorage.setItem('datalogin', JSON.stringify(profile));

  return {
    type: FETCH_LOGIN_SUCCESS, // 创建一个新的 action 类型
    login: loginState,
    datalogin: profile
  };
};

export const fetchLoginFailure = (error) => {
  return {
    type: FETCH_LOGIN_FAILURE,
    payload: error,
  };
};

// LOGOUT 
export const fetchLogoutSuccess = (loginState, profile) => {
  localStorage.setItem('login', loginState);
  localStorage.setItem('datalogin', JSON.stringify(profile) || null);

  return {
    type: FETCH_LOGOUT_SUCCESS, // 创建一个新的 action 类型
    login: loginState,
    datalogin: profile
  };
};

// UpdateBank 
export const fetchUpdateBankSuccess = (username, bank, name, account) => {
  const currentDataLogin = JSON.parse(localStorage.getItem('datalogin')) || [];
  const userIndex = currentDataLogin.findIndex(user => user.username === username);

  if (userIndex !== -1) {
    currentDataLogin[userIndex] = {
      ...currentDataLogin[userIndex],
      bank: bank,
      name: name,
      account: account,
    };
    localStorage.setItem('datalogin', JSON.stringify(currentDataLogin));
  }

  return {
    type: FETCH_UPDATE_BANK_SUCCESS,
    datalogin: currentDataLogin,
  };
};

//UpdatePassword
export const fetchUpdatePasswordSuccess = (username, password) => {
  const currentDataLogin = JSON.parse(localStorage.getItem('datalogin')) || [];
  const userIndex = currentDataLogin.findIndex(user => user.username === username);

  if (userIndex !== -1) {
    currentDataLogin[userIndex] = {
      ...currentDataLogin[userIndex],
      password: password
    };
    localStorage.setItem('datalogin', JSON.stringify(currentDataLogin));
  }

  return {
    type: FETCH_UPDATE_PASSWORD_SUCCESS,
    datalogin: currentDataLogin,
  };
};

//UpdateLocale
export const updateLocale = (locale) => {
  return {
    type: UPDATE_LOCALE,
    payload: locale,
  };
};

export const fetchUpdateBirthdaySuccess = (profile) => {
  localStorage.setItem('datalogin', JSON.stringify(profile));

  return {
    type: FETCH_UPDATEBIRTHDAY_SUCCESS, // 创建一个新的 action 类型
    datalogin: profile
  };
};

export const fetchInformation = (Language, Currency) => {
  return (dispatch) => {
    dispatch(fetchInformationRequest());
    Utils.postData( webApiUrl + 'api/react/indexinformation', { locale: Language, currency: Currency })
      .then((data) => {
        dispatch(fetchInformationSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchInformationFailure(error));
      });
  };
};

export const fetchLogin = (username, password) => {
  return (dispatch) => {
    dispatch(fetchLoginRequest());
    return Utils.postData( webApiUrl + 'api/react/fetchlogin', { username: username, password: password })
      .then((data) => {
        if(data.errCode === "0"){
          dispatch(fetchLoginSuccess(true, data.profile));
        }
        return data;
      })
      .catch((error) => {
        dispatch(fetchLoginFailure(error));
        throw error;
      });
  };
};

export const fetchLogout = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(fetchLogoutSuccess(false, {'username': null}));
      resolve();
    });
  };
};

export const fetchUpdateBank = (username, bank, name, account) => {
  return (dispatch) => {
    return Utils.postData( webApiUrl + 'api/react/fetchSetBank', { username: username, quick_bank: bank, name: name, account: account })
      .then((data) => {
        if(data.errCode === 0){
          dispatch(fetchUpdateBankSuccess(username, bank, name, account));
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchUpdatePassword = (username, password) => {
  return (dispatch) => {
    return Utils.postData( webApiUrl + 'api/react/fetchChangePassword', { 
      username: username,
      password: password
    })
    .then((data) => {
      if(data.errCode === 0){
        dispatch(fetchUpdatePasswordSuccess(username, password));
      }
      return data;
    })
    .catch((error) => {
      throw error;
    });
  };
};

export const fetchUpdateBirthday = (username, datetime) => {
  return (dispatch) => {
    return Utils.postData( webApiUrl + 'api/react/fetchUpdateBirthday', { username: username, datetime: datetime})
      .then((data) => {
        if(data.errCode === "0"){
          dispatch(fetchUpdateBirthdaySuccess(data.profile));
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };
};