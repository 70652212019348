import * as toastr from 'toastr';
import { Utils } from '../redux/utils';
import { webApiUrl } from '../defaultWebMsg'; 

export const getLanguage = (currentLanguage) => {
    switch(currentLanguage) {
        case "en":
            return "en-US";
        case "zh":
            return "zh-CN";
        case "ms":
            return "ms-MY";
        default:
            return "en-US";
    }
}

export const handleDirectGaming = (product, url) => {
    const isAndroid = navigator.userAgent.includes('Android');
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome = navigator.userAgent.includes('Chrome');
    const isApp = window.isApp || false;
    if (isAndroid) {
        if(isApp){
            if (window.Android) {
                window.Android.openGame(url, product);
            }
        }else{
            window.open(url, '_blank');
        }
    }
    else if (isiOS) {
        if(isApp){
            try {
                window.webkit.messageHandlers.iOSNative.postMessage({url: url, product: product});
            } catch(err) {
                console.log('The native context does not exist yet');
            }
        } else {
            window.open(url, '_blank');
        }
    }
    else 
    {
        window.open(url, '_blank');
    }
};

export const handleDirectGamingLobby = (product, url) => {
    const isAndroid = navigator.userAgent.includes('Android');
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome = navigator.userAgent.includes('Chrome');
    const isApp = window.isApp || false;
    if (isAndroid) 
    {
        if(isApp)
        {
            if (window.Android) {
                window.Android.startGame(url, product);
            }
        }else{
            window.open(url, '_blank');
        }
    }
    else if (isiOS) {
        if(isApp){
            try {
                window.webkit.messageHandlers.iOSNative.postMessage({url: url, product: product});
            } catch(err) {
                console.log('The native context does not exist yet');
            }
        } else {
            window.open(url, '_blank');
        }
    }
    else 
    {
        window.open(url, '_blank');
    }
};

export const handleProGame = (login, locale, product, username, category, directMethod, setLoading) => {
    const language = getLanguage(locale);

    if(login) {
        setLoading(true);
        Utils.postData( webApiUrl + 'api/react/fetchProcStart_game', { username: username, product:product, category:category, language:language})
        .then((data) => {
            setLoading(false);
            if (data.errCode !== 0) {
                toastr.error(data.errMessage);
            }else{
                handleDirectGaming(product, data.errUrl);
            }
        })
        .catch((error) => {
            setLoading(false);
        });
    }else{
        toastr.error('Please Login to Proceed');
    }
};

export const handleStart = (login, locale, category, username, product, gameCode, setLoading) => {
    const language = getLanguage(locale);
    if(login) {
        setLoading(true);
        Utils.postData( webApiUrl + 'api/react/fetchProcOpenGame', { username:username, product: product, code:gameCode, category:category, language:language})
        .then((data) => {
            setLoading(false);
            if (data.errCode !== 0) {
                toastr.error(data.errMsg);
            }else{
                handleDirectGamingLobby(product, data.errUrl);
            }
        })
        .catch((error) => {
            setLoading(false);
        });
    }else{
        toastr.error('Please Login to Proceed');
    }
}

export const handleProGameSlot = (login, product, username, category, directMethod, setLoading, setIndex, setLobby, setGameDetails) => {
    const language = getLanguage();
    if(login) {
        setLoading(true);

        Utils.postData( webApiUrl + 'api/react/fetchProcStart_game', { username: username, product:product, category:category, language:language})
        .then((data) => {
            setLoading(false);
            if (data.errCode !== 0) {
                toastr.error(data.errMessage);
            }else{

                setIndex(product);
                if (directMethod === "Url") 
                {
                    handleDirectGaming(data.errUrl);
                }else if(directMethod === "App"){
                    handleGetUsername(product, username, setGameDetails);
                }
                else
                {
                    setLobby(product);
                }
            }
        })
        .catch((error) => {
            setLoading(false);
        });
    }else{
        toastr.error('Please Login to Proceed');
    }
};

export const handleGetUsername = (index, username, setGameDetails) => {
    const product = index;
    Utils.postData( webApiUrl + 'api/react/fetchProcGetUsername', { username: username, product: product })
      .then((data) => {
        if (data.errCode !== 0) {
          toastr.error(data.errMsg);
        } else {
          setGameDetails(index, data.username, data.password, data.urlDownload);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
};

export const handleProMobileGameSlot = (login, locale, product, username, category, directMethod, setLoading) => {
    const language = getLanguage(locale);
    if(login) {
        setLoading(true);

        Utils.postData( webApiUrl + 'api/react/fetchProcStart_game', { username: username, product:product, category:category, language:language})
        .then((data) => {
            setLoading(false);
            if (data.errCode !== 0) {
                toastr.error(data.errMessage);
            }else{
                if (directMethod === "Url") {
                    handleDirectGaming(product, data.errUrl);
                }
                
                if(directMethod === "App" || directMethod === "Lobby" ) {
                    let urlPrefix = "/slot/lobby/";
                    if(locale === "zh") {
                        urlPrefix = "/zh/slot/lobby/";
                    } else if(locale === "ms") {
                        urlPrefix = "/ms/slot/lobby/";
                    }

                    window.location.href = urlPrefix + product;
                }
            }
        })
        .catch((error) => {
            setLoading(false);
        });
    }else{
        toastr.error('Please Login to Proceed');
    }
};

export const fetchWithdrawGame = (username) => {
    Utils.postData( webApiUrl + 'api/react/fetchWithdrawGame', { username: username })
    .then((data) => {
        return data;
    })
    .catch((error) => {
    });
}

export const fetchRestore = (username) => {
    return Utils.postData( webApiUrl + 'api/react/fetchRestore', { username: username })
    .then((data) => {
        return data;
    })
    .catch((error) => {
    });
}

export const fetchEditGamePassword = (username, password, product, setLoading) => {
    setLoading({ loading: true });
    Utils.postData( webApiUrl + 'api/react/fetchEditGamePassword', { 
        username: username,
        password: password, 
        product: product
    })
    .then((data) => {
        if (data.errCode === 0) {
            toastr.success(data.errMessage);
            window.location.reload();
        } else {
            toastr.error(data.errMessage);
        }  
    })
    .catch((error) => {
        setLoading({ loading: false });
        //console.error(error);
    });
}