import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { informationReducer } from './reducers';
import LoginReducer from './loginReducer';
import { localeReducer } from './localeReducer';

const rootReducer = combineReducers({
  information: informationReducer,
  loginstatus: LoginReducer, // Add LoginReducer
  locale: localeReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
