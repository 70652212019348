import React, { useState, useEffect }from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

import Loading from './staticComponent/FullPageLoader';
import MetaTags from './staticComponent/MetaTags';
import createMetaData from './staticComponent/metaData';
import { fetchWithdrawGame } from './utils/gameProduct';

import { useDispatch, useSelector } from 'react-redux';
import { updateLocale as updateLocaleAction } from './redux/actions'; 
import { IntlProvider } from "react-intl";
import { getIntl } from "./staticComponent/i18n";
import './staticComponent/i18n';
import './headFolder/css/toastr.min.css';
import './headFolder/css/mobile.css';
import './headFolder/css/mobilemrp.css';
import './headFolder/css/desktop.css';

const SUPPORTED_LANGUAGES = ["en", "ms", "zh"];
const MOBILE_BREAKPOINT = 768;

const LanguageDetector = () => {
  const location = useLocation();
  const dispatch = useDispatch(); 
  
  useEffect(() => {
    const langFromPath = location.pathname.split("/")[1];
    
    if (SUPPORTED_LANGUAGES.includes(langFromPath)) {
      dispatch(updateLocaleAction(langFromPath)); //updateLocale(langFromPath);
      localStorage.setItem("userLanguage", langFromPath);
    } else {
      dispatch(updateLocaleAction('en')); //updateLocale("en"); // 默认语言是英语
      localStorage.setItem("userLanguage", "en");
    }
    
  }, [location]);
  
  return null;
};

const MetaTagsWrapper = ({intl}) =>  {
  const location = useLocation();

  const currentMetaData = createMetaData(intl, location);

  return (
    <MetaTags 
      title={currentMetaData.title} 
      description={currentMetaData.description} 
      canonicalUrl={currentMetaData.canonicalUrl}
    />
  );
}

const LoadingComponent = ({ isMobile, setIsLoaded }) => {
  /*useEffect(() => {
    if (isMobile) {
      Promise.all([
        import('./headFolder/css/mobile.css'),
        import('./headFolder/css/mobilemrp.css')
      ]).then(() => {
        setIsLoaded(false); // 所有CSS加载完成后，设置IsLoaded为true
      });
    } else {
      import('./headFolder/css/desktop.css').then(() => {
        setIsLoaded(false); // CSS加载完成后，设置IsLoaded为true
      });
    }
  }, [isMobile, setIsLoaded]); // 添加isMobile和setIsLoaded作为依赖*/

  useEffect(() => {
    if (isMobile) {
      setIsLoaded(false);
    } else {
      setIsLoaded(false);
    }
  }, [isMobile, setIsLoaded]);

  // 在此处，你可以根据你的需要显示你想要的加载指示器
  return <Loading/>;
};

const GAMES = [
  'SPADEGAMING', 'ACEWIN', 'JOKER', 'PRAGMATICPLAY', 'MEGA',
  'K918', 'PUSSY888', 'MICROGAMING', 'CQ9', 'HABANERO',
  'XE88', 'XINGAMING', 'SIMPLEPLAY', 'NETENT', 'NextSpin',
  'EVO8', 'PLAYTECH', 'MARIOCLUB', 'PUSSY888'
];

const LANG_PREFIXES = ['', 'zh/', 'ms/'];

const EXCLUDED_ROUTES = [];

GAMES.forEach(game => {
  LANG_PREFIXES.forEach(prefix => {
    EXCLUDED_ROUTES.push(`/${prefix}slot/lobby/${game}`);
  });
});

const WithdrawComponent = () => {
  const login = useSelector(state => state.loginstatus.login);
  const username = useSelector(state => (state.loginstatus.datalogin && state.loginstatus.datalogin.length > 0) ? state.loginstatus.datalogin[0].username : '');
  const location = useLocation();

  useEffect(() => {
    if (login && !EXCLUDED_ROUTES.includes(location.pathname)) {
      fetchWithdrawGame(username); // 确保你已经定义了这个函数
    }
  }, [location, login]);

  return null;
};

const MobileLayout = React.lazy(() => import('./MobileLayout'));
const DesktopLayout = React.lazy(() => import('./DesktopLayout'));

const App = () => {
  const localeRedux = useSelector(state => state.locale.locale);
  const intl = getIntl(localeRedux); //设置语言
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT); 
  const [isLoaded, setIsLoaded] = useState(true); // 新增isLoaded状态，初始为false
  
  return (
    <IntlProvider {...intl}>
      <BrowserRouter>
        <MetaTagsWrapper intl={intl}/>
        {<LanguageDetector />}
        <WithdrawComponent />
        {
          isLoaded ?
          <LoadingComponent isMobile={isMobile} setIsLoaded={setIsLoaded} /> : // 如果CSS未加载完成，就显示Loading组件
          (
            <React.Suspense fallback={<Loading/>}>
              {isMobile ? <MobileLayout /> : <DesktopLayout />}
            </React.Suspense>
          )
        }
      </BrowserRouter>
    </IntlProvider>
  );
};

export default App;