import { 
  FETCH_LOGIN_REQUEST, 
  FETCH_LOGIN_SUCCESS, 
  FETCH_LOGIN_FAILURE, 
  FETCH_LOGOUT_SUCCESS, 
  FETCH_UPDATE_BANK_SUCCESS, 
  FETCH_UPDATE_PASSWORD_SUCCESS,
  FETCH_UPDATEBIRTHDAY_SUCCESS
}from './actions';

const initialState = {
  login: JSON.parse(localStorage.getItem('login')) || false,
  datalogin: JSON.parse(localStorage.getItem('datalogin')) || [],
  loadingLogin: false,
  error:null
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN_REQUEST:
      return { ...state, loadingLogin: true };
    case FETCH_LOGIN_SUCCESS:
      return { ...state, loadingLogin: false, login: action.login, datalogin: action.datalogin };
    case FETCH_LOGIN_FAILURE:
      return { ...state, loadingLogin: false, error: action.error };
    case FETCH_LOGOUT_SUCCESS:
      return { ...state, loadingLogin: false, login: action.login, datalogin: action.datalogin };
    case FETCH_UPDATE_BANK_SUCCESS:
      return { ...state, datalogin: action.datalogin };
    case FETCH_UPDATE_PASSWORD_SUCCESS:
      return { ...state, datalogin: action.datalogin };
    case FETCH_UPDATEBIRTHDAY_SUCCESS:
      return { ...state, datalogin: action.datalogin };
    default:
      return state;
  }
};

export default LoginReducer;