import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, canonicalUrl }) => (
    <Helmet>
        <meta property="og:url" content={`${window.location.origin}`} />   
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="x-default" href={`${window.location.origin}`} />
        <link rel="alternate" hreflang="en" href={`${window.location.origin}`} />
        <link rel="alternate" hreflang="ms" href={`${window.location.origin}/ms`} />
        <link rel="alternate" hreflang="zh" href={`${window.location.origin}/zh`} />
    </Helmet>
);

export default MetaTags;