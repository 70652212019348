import { FETCH_INFORMATION_REQUEST, FETCH_INFORMATION_SUCCESS, FETCH_INFORMATION_FAILURE } from './actions';

const initialState = {
  loadingInformation: false,
  information: {},
  error: null,
};

export const informationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INFORMATION_REQUEST:
      return { ...state, loadingInformation: true };
    case FETCH_INFORMATION_SUCCESS:
      return { ...state, loadingInformation: false, information: action.payload };
    case FETCH_INFORMATION_FAILURE:
      return { ...state, loadingInformation: false, error: action.payload };
    default:
      return state;
  }
};