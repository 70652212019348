import { createIntl, createIntlCache } from "react-intl";
import enMessages from "../translations/en.json";
import zhMessages from "../translations/zh.json";
import msMessages from "../translations/ms.json";

const cache = createIntlCache();

const messages = {
  en: enMessages,
  zh: zhMessages,
  ms: msMessages,
};

export function getIntl(locale) {
  return createIntl({ locale, messages: messages[locale] }, cache);
}